<template>
  <div class="home" style="">
    <button @click="change(1)">RTMP</button>
    <button @click="change(2)">MP4</button>
    <rtmp-player
      :width="320"
      :height="190"
      :muted="true"
      :autoplay="true"
      :controls="true"
      :source="src"
      :type="type"
    />
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import "rtmp-player/dist/rtmp-player.css";
import rtmpPlayer from "rtmp-player";

export default {
  name: "liveArtcCmp",
  data() {
    return {
      type: null,
      options: {
        autoplay: true,
        controls: true,
        preload: "auto",
        width: 320,
        height: 190,
      },
      src: "rtmp://58.200.131.2:1935/livetv/cctv14",
    };
  },

  components: { rtmpPlayer },
  methods: {
    change(val) {
      switch (val) {
        case 1:
          this.src = "rtmp://58.200.131.2:1935/livetv/cctv14";
          break;
        case 2:
          this.type = "video/mp4";
          this.src = "http://resource.wangdaodao.com/402670506.mp4";
          break;
      }
    },
  },

  mounted() {},

  async destroyed() {},
  created() {},
  beforeCreate() {},
};
</script>
<style></style>
