<style scoped>
.back-icon {
  height: 40px;
  width: 40px;
  /* background: #de7d7d; */
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid gainsboro; */
  box-shadow: 0 0 5px rgb(191 88 88 / 54%);
  line-height: 40px;
  text-align: center;
  /* color: white; */
  color: #bf5858;
  font-weight: bold;
}
.back-icon-click {
  background: white;
}
</style>
<template>
  <div id="back-icon" class="back-icon" @click="goBack">
    <i class="fa fa-angle-left fa-lg" aria-hidden="true"></i>
  </div>
</template>

<script>
import debug from "debug";
const _d = debug("@pages:backIcon");
_d("backIcon");

export default {
  name: "back-icon",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    // pushBackIcon() {
    //   document.getElementById("back-icon").classList.remove("back-icon-click");
    // },
  },
};
</script>
