<template>
  <div>
    <video-player
      class="vjs-custom-skin"
      :options="playerOptions"
      :playsinline="true"
      customEventName="customstatechangedeventname"
    ></video-player>
  </div>
</template>

<script>
/* eslint-disable */
// rtmp：type:‘rtmp/flv’
// flv：type:‘video/x-flv’

import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css"; //videoJs的样式
import "vue-video-player/src/custom-theme.css"; //vue-video-player的样式
import "videojs-contrib-hls"; //引入才能播放m3u8文件

import liveConfig from "../api/liveConfig";
export default {
  name: "liveHlsCmp",
  data() {
    return {
      playerOptions: {
        // muted: true,
        width: document.documentElement.clientWidth,
        height: 190,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        sources: [
          {
            withCredentials: true,
            type: "application/x-mpegURL",
            src: liveConfig.hlsUrl,
          },
        ],
        language: "zh-CN",
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        poster: "/img/logo.jpeg",

        autoplay: true,
      },
    };
  },

  props: {
    // 内容数据
    pullStreamUrl: {
      type: String,
      default: liveConfig.artcUrl,
    },
  },
  components: { videoPlayer },
  methods: {},

  mounted() {},
  created() {
    if (this.pullStreamUrl) {
      console.log(
        "11111----",
        this.pullStreamUrl,
        this.playerOptions.sources[0].src
      );
      this.playerOptions.sources[0].src = this.pullStreamUrl;
      console.log(
        "22222----",
        this.pullStreamUrl,
        this.playerOptions.sources[0].src
      );
    }
  },
  beforeCreate() {},
};
</script>
<style>
.video-js.vjs-custom-skin .vjs-big-play-button {
  height: 1em !important;
  line-height: 1em !important;
  font-size: 1rem;
  margin-top: auto;
  text-align: center;
}
.video-js .vjs-big-play-button {
  top: 42% !important;
  left: 43% !important;
  border-radius: 50% !important;
  width: 1.5em !important;
  height: 1.5em !important;
  text-align: center;
}
.video-js {
  background-color: white;
  /* height: 18rem;
  width: 100%;
  background-size: 100%; */
}
/* .vjs-poster {
  background-color: transparent;
  background-position: top center;
  background-size: 80%;
}
.vjs_video_3-dimensions {
  width: 100%;
  display: block;
} */
.vjs-poster {
  background-color: white;
  /* background-color: rgba(0, 0, 0, 0.6) !important;
  background-color: transparent !important;
  background-size: cover !important; */
}
</style>
