<template>
  <div class="home" style="">
    <div style="width: 100%; min-height: 190px">
      <!-- <live-hls-cmp
        v-if="liveDetail.liveType == 'HLS'"
        :pullStreamUrl="liveDetail.liveUrl ? liveDetail.liveUrl : ''"
      ></live-hls-cmp> -->
      <live-xg-hls-cmp
        v-if="liveDetail.liveType == 'HLS'"
        :pullStreamUrl="liveDetail.liveUrl ? liveDetail.liveUrl : ''"
      ></live-xg-hls-cmp>
      <live-artc-cmp
        v-else
        :pullStreamUrl="liveDetail.liveUrl ? liveDetail.liveUrl : ''"
      ></live-artc-cmp>

      <!--
      <live-rtmp-cmp></live-rtmp-cmp>
        <live-xg-web-rtc-cmp></live-xg-web-rtc-cmp> - 
        <live-xg-hls-cmp
        v-if="liveDetail.liveType == 'HLS'"
        :pullStreamUrl="liveDetail.liveUrl ? liveDetail.liveUrl : ''"
      ></live-xg-hls-cmp> -->
    </div>
    <live-detail-cmp :liveDetail="liveDetail"></live-detail-cmp>
    <back-icon
      @goBack="goBack"
      style="position: fixed; bottom: 20%; right: 20px; z-index: 9999"
    ></back-icon>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import liveArtcCmp from "../widgets/liveArtcCmp";
import liveHlsCmp from "../widgets/liveHlsCmp";
import liveXgFlvCmp from "../widgets/liveXgFlvCmp";
import liveRtmpCmp from "../widgets/liveRtmpCmp";
import liveRtmpCmp1 from "../widgets/liveRtmpCmp1";
import liveXgHlsCmp from "../widgets/liveXgHlsCmp";
// import liveXgWebRtcCmp from "../widgets/liveXgWebRtcCmp";

import BackIcon from "../widgets/backIcon";
import wxLoginApi from "../api/wxLoginApi";
import { wxConPost } from "../api/httpApi";
import liveDetailCmp from "../widgets/liveDetail";

export default {
  name: "Home",
  data() {
    return {
      title: "",
      time: "",
      liveEnterId: "",
      liveDetail: {},
    };
  },

  components: {
    BackIcon,
    liveDetailCmp,
    liveArtcCmp,
    liveHlsCmp,
    liveXgFlvCmp,
    liveRtmpCmp,
    liveRtmpCmp1,
    liveXgHlsCmp,
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/",
      });
    },
    async uploadStatus() {
      try {
        const ret = await wxConPost("/services/gttt-live-mgt/wxLive/addWxLog", {
          _cid: this.liveDetail._cid,
        });
        this.liveEnterId = ret.ret;
      } catch (error) {
        console.log("----", error);
      }
    },
    async updateStatus() {
      try {
        if (this.liveEnterId) {
          const ret = await wxConPost(
            "/services/gttt-live-mgt/wxLive/addWxLog",
            {
              liveEnterId: this.liveEnterId,
            }
          );
        }
      } catch (error) {
        console.log("----", error);
      }
    },
  },

  mounted() {},
  async destroyed() {
    console.log("destroyed", this.liveEnterId);
    await this.updateStatus();
  },
  created() {
    const queryInfo = this.$route.query ? this.$route.query : {};
    console.log("queryInfo---", queryInfo);
    document.title = queryInfo.title ? queryInfo.title : "";
    this.liveDetail = queryInfo;
    wxLoginApi.onReady(() => {
      console.log("用户登录成功，设置背白色调");
      setTimeout(async () => {
        // 进入2s 开始上报
        await this.uploadStatus();
      }, 2000);
    });
  },
  beforeCreate() {
    // document.title = title;
  },
};
</script>
<style></style>
