<template>
  <div style="" class="home">
    <div id="mse"></div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import Player from "xgplayer";
import FlvPlayer from "xgplayer-flv";

export default {
  name: "liveXgFlvCmp",
  data() {
    return {
      roomInfo: {
        updated: new Date(),
      },

      xgPlayer: null,
    };
  },
  props: {
    url: {
      type: String,
      default: 0,
    },
  },
  components: {},
  methods: {
    initXg() {
      let player = new FlvPlayer({
        id: "mse",
        url: "http://zjonline.gtrmt.cn/live/gtrmt.flv",
        // url:
        //   "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
        // url:
        //   "https://play.magicmind.cn/gt/room1.flv?auth_key=1612755674-0-0-1d6d78bb7a1e627e2506a953b13c979b",

        isLive: true,
        playsinline: true,
        "x5-video-player-type": "h5",
        autoplay: true,
        width: "100%",
        // height: 500,
        // width: window.innerWidth,
        // height: (window.innerWidth * 9) / 16,
        isLive: true,
        controls: true,
        preloadTime: 5,
        minCachedTime: 1,
        cors: true, // 请求是否跨域
      });
      // player.emit("resourceReady", [
      //   {
      //     name: "超清",
      //     url:
      //       "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-720p.flv",
      //   },
      //   {
      //     name: "高清",
      //     url:
      //       "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-480p.flv",
      //   },
      //   {
      //     name: "标清",
      //     url:
      //       "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
      //   },
      // ]);

      // player.getCssFullscreen();  // 全屏
      player.once("ready", () => {
        console.log("ready");
      });
      player.on("rotate", function (deg) {
        console.log(deg); // 旋转时会触发rotate事件，角度有四个值90，180，270，0
      });
    },
    resizeHandler: () => {
      document.getElementById("mse").style.height =
        (window.innerWidth * 16) / 9 + "px";
    },
  },

  mounted() {
    this.initXg();
    // window.onresize = () => this.resizeHandler();
  },
  created() {},
  beforeCreate() {},
  destroyed() {
    this.player.destroy(false);
  },
};
</script>
<style>
</style>
