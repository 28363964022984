<template>
  <div class="home" style="">
    <video
      id="aliVideo"
      width="100%"
      height="190px"
      ref="aliVideo"
      @play="play"
      controls
    ></video>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import AliRTS from "aliyun-rts-sdk";
import liveConfig from "../api/liveConfig";

export default {
  name: "liveArtcCmp",
  data() {
    return {
      muted: false,
      aliRts: null,
      loading: false, // 缓存中
      loadingTime: 0, // 重载次数
    };
  },

  props: {
    // 内容数据
    pullStreamUrl: {
      type: String,
      default: liveConfig.artcUrl,
    },
  },
  components: {},
  methods: {
    /**
     *
     */
    play() {
      console.log("play muteLiveStream");
      this.aliRts.muteLiveStream(false);
    },
    async initLive() {
      try {
        // 1.实例化
        // 这里模式设置为直播模式，视频编码使用VP8编码
        this.aliRts = new AliRTS();
        /**
         * 1. isSupport检测是否可用
         * @param {Object} supportInfo 检测信息
         * @param {Boolean} supportInfo.isReceiveVideo 是否拉视频流
         * @return {Promise}
         */
        this.aliRts
          .isSupport(window)
          .then((re) => {
            // 可用
            console.log("可用", re);
          })
          .catch((err) => {
            // 不可用
            console.log(`not support errorCode: ${err}`);
            console.log(`not support message: ${err}`);
          });
        /**
         * rts开始拉流接口
         * @param {String} pullStreamUrl 拉流地址,在地址后添加@subaudio=no或者@subvideo=no来表示不订阅音频流或视频流
         * @param {HTMLMediaElement} mediaElement 媒体标签audio或video
         * @return {Promise}
         */
        console.log("aliVideo", document.getElementById("aliVideo"));
        this.aliRts.startLiveStream(
          this.pullStreamUrl,
          document.getElementById("aliVideo")
        );

        /**
         * 拉流静音接口
         * @param {Boolean} muted 是否开启静音
         */
        this.aliRts.muteLiveStream(true);
        /*
         * 在onError中获取到错误码10201时，此时网页的音频是静音的，
         * 需要用户在网页上手动触发事件（必须有用户交互，不能直接通过代码控制）
         * 调用aliRts.muteLiveStream(false) 来取消静音
         */
        this.aliRts.on("onError", (err) => {
          console.log(`errorCode: ${err.errorCode}`, err);
          console.log(`message: ${err.message}`);
          /**
           * 停止rts播放接口
           */
          this.aliRts.stopLiveStream();
          this.aliRts = null;
          this.initLive();
        });

        const PLAY_EVENT = {
          CANPLAY: "canplay",
          WAITING: "waiting",
          PLAYING: "playing",
        };

        this.aliRts.on("onPlayEvent", (play) => {
          // console.log('aliRts.on.onPlayEvent--', play);
          if (play.event == "media") {
            // console.log('缓存中');
            this.loadingTime += 1;
          } else {
            // console.log('缓存中+1', play.event);
            this.loadingTime = 0;
          }
          if (play.event === PLAY_EVENT.CANPLAY) {
            //拉流可以播放
            this.loading = true;
            console.log("拉流可以播放");
          } else if (play.event === PLAY_EVENT.WAITING) {
            //拉流卡顿等待缓冲中 （仅Chrome）
            this.loading = true;
            console.log("拉流卡顿等待缓冲中");
          } else if (play.event === PLAY_EVENT.PLAYING) {
            // 拉流卡顿结束恢复播放 （仅Chrome）
            this.loading = true;
            console.log("拉流卡顿结束恢复播放");
          } else if (play.event == "ended") {
            console.log("播放结束");
            this.aliRts.stopLiveStream();
            this.aliRts = null;
            this.initLive();
          }
        });
      } catch (e) {
        this.loading = false;
        console.log("live ERROR", e);
      }
    },
  },

  mounted() {
    this.initLive();
  },

  async destroyed() {
    /**
     * 停止rts播放接口
     */
    this.aliRts.stopLiveStream();
    this.aliRts = null;
  },
  created() {},
  beforeCreate() {},
};
</script>
<style>
#app .vjs-custom-skin > .video-js .vjs-big-play-button {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px !important;
  text-align: center;
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 50% !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -25px !important;
  margin-top: -25px !important;
  font-size: 35px !important;
  border: none !important;
}

#app .video-js {
  background-color: transparent !important;
}
#app .vjs-poster {
  background-color: transparent !important;
  /* background-size: cover !important; */
}
</style>
