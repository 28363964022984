<template>
  <div class="liveDetail">
    <input id="tab1" type="radio" name="tabs" checked />
    <label for="tab1">简介</label>

    <input id="tab2" type="radio" name="tabs" />
    <label for="tab2"> 讨论区 </label>

    <section id="content1" class="content">
      <div style="margin-top: 15px; font-size: 12px">
        <div class="marTop">
          {{ roomInfo.roomName }}
        </div>
        <div></div>
      </div>
      <div style="margin-top: 15px; font-size: 12px">
        <div class="marTop">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          &nbsp;&nbsp;
          {{ liveDetail.time ? liveDetail.time : formatDate(new Date()) }}
        </div>
      </div>
      <div class="marTop">
        <!-- <span class="tips">网页回放</span> -->
        <span class="tips onLive">直播 </span>
        <!-- <span class="tips">云端录播</span> -->
      </div>
    </section>
    <section id="content2" class="talkContent">
      <div></div>
      <div>
        <!-- 评论 -->
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "liveDetail",
  data() {
    return {
      roomInfo: {
        updated: new Date(),
      },
      roomId: "",
      ifView: false,
      isLoginIn: true,
    };
  },
  props: {
    // 内容数据
    liveDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  components: {},
  methods: {
    formatDate(updated) {
      const date = new Date(updated);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      console.log(
        updated,
        date,
        year + "/" + this.formatTen(month) + "/" + this.formatTen(day)
      );
      return (
        year +
        "/" +
        this.formatTen(month) +
        "/" +
        this.formatTen(day) +
        " " +
        this.formatTen(hour) +
        ":" +
        this.formatTen(minute)
        // ":" +
        // this.formatTen(second)
      );
    },
    formatTen(num) {
      return num > 9 ? num + "" : "0" + num;
    },
    formatDate(updated) {
      const date = new Date(updated);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      console.log(
        updated,
        date,
        year + "/" + this.formatTen(month) + "/" + this.formatTen(day)
      );
      // const second = date.getSeconds();
      return (
        year +
        "/" +
        this.formatTen(month) +
        "/" +
        this.formatTen(day) +
        " " +
        this.formatTen(hour) +
        ":" +
        this.formatTen(minute)
        // ":" +
        // this.formatTen(second)
      );
    },
    goBack() {
      this.$router.push({
        path: "/",
      });
    },
  },

  mounted() {},
  async destroyed() {},
  created() {},
  beforeCreate() {},
};
</script>
<style scoped>
.liveDetail {
  border-top: 1px solid #ccc;
  text-align: left;
  background: #f2f2f2;
  min-height: 500px;
}
.talkContent {
  padding: 20px 10px;
  color: red;
  text-align: center;
}
/**
  TAB样式
*/
section {
  display: none;
  background: white;
  min-height: 500px;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
}
input {
  display: none;
}
label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 10px 20px;
  font-weight: 600;
  color: #bbb;
  text-align: center;
}

label:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
}

label[for*="1"]:before {
  content: "\f1cb";
}

label[for*="2"]:before {
  content: "\f17d";
}

label[for*="3"]:before {
  content: "\f16b";
}

label[for*="4"]:before {
  content: "\f1a9";
}

label:hover {
  color: #888;
  cursor: pointer;
}

input:checked + label {
  color: #555;
  background: white;
  border-bottom: none;
}

.content {
  padding: 0 10px;
}
#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4 {
  display: block;
}
</style>
